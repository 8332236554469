import React, { useContext, useEffect } from 'react';
import {
  Center,
  Heading,
  Box,
  Button,
  Text,
  HStack,
  ButtonText,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  Actionsheet,
  Progress,
  ProgressFilledTrack,
  Badge,
  BadgeText,
  Spinner,
  ButtonGroup,
  Icon,
  ChevronDownIcon,
  InfoIcon,
  AlertText,
  Alert,
  AlertIcon,
  TooltipContent,
  TooltipText,
  Tooltip,
} from '@gluestack-ui/themed';
import { dashboardAPI } from '../DashboardAPI';
import { AuthContext } from '../AuthProvider';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import {
  Dimensions,
  FlatList,
  ScrollView,
  StatusBar,
  TouchableOpacity,
  View,
} from 'react-native';
import { SafeAreaView, StyleSheet } from 'react-native';

import { PieChart } from 'react-native-chart-kit';
import RoiSpeedometer from './shared/RoiSpeedometer';
import DurationAvatar from './shared/DurationAvatar';
import Loading from './shared/Loading';
import CryptoCard from './shared/CryptoCard';
import Play from './shared/Play';

export default function HomeStats() {
  const {
    user,
    rhSessionExpirationDate,
    rhEmail,
    rhCryptoSetUp,
    marketApps,
    playSettings,
    refreshMarketApps,
  } = useContext(AuthContext);
  const isFocused = useIsFocused();

  const [totalGains, setTotalGains] = React.useState(0.0);

  const [viewDetails, setViewDetails] = React.useState(false);
  const [totalGainsLoading, setTotalGainsLoading] = React.useState(true);
  const [totalGainsDetails, setTotalGainsDetails] = React.useState(null);
  const [exchangePrices, setExchangePrices] = React.useState(null);
  const [cryptoSettings, setCryptoSettings] = React.useState(null);
  const [totalOpenFunds, setTotalOpenFunds] = React.useState(0);
  const [totalOpenFundsBull, setTotalOpenFundsBull] = React.useState(0);
  const [totalInUseFundsBull, setTotalInUseFundsBull] = React.useState(0);
  const [totalInUseFundsBear, setTotalInUseFundsBear] = React.useState(0);
  const [gainsTimePeriod, setGainsTimePeriod] = React.useState('ALL_TIME');
  const [totalGainsToolTipState, setTotalGainsToolTipState] =
    React.useState(false);

  const [completedPlaysToolTipState, setCompletedPlaysToolTipState] =
    React.useState(false);
  const [winLossRatioToolTipState, setWinLossRatioToolTipState] =
    React.useState(false);
  const [ROIToolTipState, setROIToolTipState] = React.useState(false);
  const [playDurationToolTipState, setPlayDurationToolTipState] =
    React.useState(false);

  const [openBullFundsPercentage, setOpenBullFundsPercentage] =
    React.useState(0.0);
  const [openBearFundsPercentage, setOpenBearFundsPercentage] =
    React.useState(0.0);
  const [gaugeBullFundsColor, setGaugeBullFundsColor] =
    React.useState('$green600');
  const [gaugeBearFundsColor, setGaugeBearFundsColor] =
    React.useState('$green600');

  const [displayBullFundWarningBadge, setDisplayBullFundWarningBadge] =
    React.useState(false);
  const [titleBullFundWarningBadge, setTitleBullFundWarningBadge] =
    React.useState('LOW ON BULL FUNDS');
  const [colorBullFundWarningBadge, setColorBullFundWarningBadge] =
    React.useState('warning');

  const [displayBearFundWarningBadge, setDisplayBearFundWarningBadge] =
    React.useState(false);
  const [titleBearFundWarningBadge, setTitleBearFundWarningBadge] =
    React.useState('LOW ON BEAR FUNDS');
  const [colorBearFundWarningBadge, setColorBearFundWarningBadge] =
    React.useState('warning');

  const [roiGaugePlayState, setRoiGaugePlayState] = React.useState('completed');
  const [roiGaugeValue, setRoiGaugeValue] = React.useState(4);
  const [avgDuration, setAvgDuration] = React.useState(4);

  const [closedPlays, setClosedPlays] = React.useState(0);
  const [openedPlays, setOpenedPlays] = React.useState(0);
  const [canceledPlays, setCanceledPlays] = React.useState(0);

  const [playsPieChart, setPlaysPieChart] = React.useState(null);

  const [cryptoCards, setCryptoCards] = React.useState([]);
  const [cryptoCardsNotInvesting, setCryptoCardsNotInvesting] = React.useState(
    [],
  );
  const [lapsedFundsUsd, setLapsedFundsUsd] = React.useState(null);
  const [lapsedFundsMxn, setLapsedFundsMxn] = React.useState(null);

  const [showPlaySettingsSheet, setPlaySettingsSheet] = React.useState(false);

  const navigation = useNavigation();
  useEffect(() => {}, [
    totalGainsToolTipState,
    playDurationToolTipState,
    completedPlaysToolTipState,
    ROIToolTipState,
    winLossRatioToolTipState,
    marketApps,
    playSettings,
  ]);
  useEffect(() => {
    if (isFocused) {
      (async () => {
        if (user) {
          getLapsedFunds();

          dashboardAPI.getOpenFundCounts(user).then((x) => {
            setTotalOpenFunds(x.data.total_open_funds);
            setTotalOpenFundsBull(x.data.total_open_funds_bull);

            if (
              x.data.total_in_use_funds_bull + x.data.total_open_funds_bull >
              0
            ) {
              let bullGaugePercentage =
                100.0 *
                (x.data.total_open_funds_bull /
                  (x.data.total_in_use_funds_bull +
                    x.data.total_open_funds_bull));
              if (bullGaugePercentage < 5) {
                bullGaugePercentage = 5;
              }

              if (bullGaugePercentage < 10) {
                setGaugeBullFundsColor('$red600');
                setDisplayBullFundWarningBadge(true);
              } else if (bullGaugePercentage < 30) {
                setGaugeBullFundsColor('$amber600');
              } else if (bullGaugePercentage < 70) {
                setGaugeBullFundsColor('$green600');
              } else {
                setGaugeBullFundsColor('$primary');
              }
              setOpenBullFundsPercentage(bullGaugePercentage);
              setTotalInUseFundsBull(x.data.total_in_use_funds_bull);
            } else {
              setColorBullFundWarningBadge('error');
              setTitleBullFundWarningBadge('NO BULL FUNDS OPEN');
              setDisplayBullFundWarningBadge(true);
            }
            if (
              x.data.total_open_funds -
                x.data.total_open_funds_bull +
                (x.data.total_in_use_funds - x.data.total_in_use_funds_bull) >
              0
            ) {
              const total_in_use_funds_bear =
                x.data.total_in_use_funds - x.data.total_in_use_funds_bull;

              const total_open_funds_bear =
                x.data.total_open_funds - x.data.total_open_funds_bull;
              let bearGaugePercentage =
                100.0 *
                (total_open_funds_bear /
                  (total_in_use_funds_bear + total_open_funds_bear));
              if (bearGaugePercentage < 5) {
                bearGaugePercentage = 5;
              }
              if (bearGaugePercentage < 10) {
                setGaugeBearFundsColor('$red600');
                setDisplayBearFundWarningBadge(true);
              } else if (bearGaugePercentage < 30) {
                setGaugeBearFundsColor('$amber600');
              } else if (bearGaugePercentage < 70) {
                setGaugeBearFundsColor('$green600');
              } else {
                setGaugeBearFundsColor('$primary');
              }

              setOpenBearFundsPercentage(bearGaugePercentage);
              setTotalInUseFundsBear(total_in_use_funds_bear);
            } else {
              setColorBearFundWarningBadge('error');
              setTitleBearFundWarningBadge('NO BEAR FUNDS OPEN');
              setDisplayBearFundWarningBadge(true);
            }
          });

          dashboardAPI
            .getTotalGainsForTimePeriod(user, gainsTimePeriod)
            .then((x) => {
              setCanceledPlays(x.data.play_summary.canceled_plays);
              setOpenedPlays(x.data.play_summary.canceled_plays);
              setClosedPlays(x.data.play_summary.closed_plays);
              setRoiGaugeValue(x.data.play_summary.closed_plays_roi.toFixed(1));
              setAvgDuration(x.data.play_summary.closed_plays_duration);

              let pieChart = getPlayPieChartData(x);
              setPlaysPieChart(pieChart);

              let savingsNew =
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.ETH_SAVINGS) *
                    x.data.exchange_prices.ETH
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.DOGE_SAVINGS) *
                    x.data.exchange_prices.DOGE
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.BTC_SAVINGS) *
                    x.data.exchange_prices.BTC
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.BCH_SAVINGS) *
                    x.data.exchange_prices.BCH
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.ADA_SAVINGS) *
                    x.data.exchange_prices.ADA
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.SHIB_SAVINGS) *
                    x.data.exchange_prices.SHIB
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.DOT_SAVINGS) *
                    x.data.exchange_prices.DOT
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.SOL_SAVINGS) *
                    x.data.exchange_prices.SOL
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.MATIC_SAVINGS) *
                    x.data.exchange_prices.MATIC
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.XRP_SAVINGS) *
                    x.data.exchange_prices.XRP
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.AVAX_SAVINGS) *
                    x.data.exchange_prices.AVAX
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.LINK_SAVINGS) *
                    x.data.exchange_prices.LINK
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.UNI_SAVINGS) *
                    x.data.exchange_prices.UNI
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.ETC_SAVINGS) *
                    x.data.exchange_prices.ETC
                  ).toFixed(2),
                );

              let totalGainsNew =
                parseFloat(x.data.total_gains.cash) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.ETH) *
                    x.data.exchange_prices.ETH
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.DOGE) *
                    x.data.exchange_prices.DOGE
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.BTC) *
                    x.data.exchange_prices.BTC
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.BCH) *
                    x.data.exchange_prices.BCH
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.AVAX) *
                    x.data.exchange_prices.AVAX
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.SHIB) *
                    x.data.exchange_prices.SHIB
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.DOT) *
                    x.data.exchange_prices.DOT
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.ADA) *
                    x.data.exchange_prices.ADA
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.SOL) *
                    x.data.exchange_prices.SOL
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.MATIC) *
                    x.data.exchange_prices.MATIC
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.XRP) *
                    x.data.exchange_prices.XRP
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.LINK) *
                    x.data.exchange_prices.LINK
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.UNI) *
                    x.data.exchange_prices.UNI
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.ETC) *
                    x.data.exchange_prices.ETC
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.XLM) *
                    x.data.exchange_prices.XLM
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.COMP) *
                    x.data.exchange_prices.COMP
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.AAVE) *
                    x.data.exchange_prices.AAVE
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.XTZ) *
                    x.data.exchange_prices.XTZ
                  ).toFixed(2),
                ) +
                parseFloat(
                  (
                    parseFloat(x.data.total_gains.LTC) *
                    x.data.exchange_prices.LTC
                  ).toFixed(2),
                );
              if (isNaN(totalGainsNew)) {
                setTotalGains(0.0);
              } else {
                setTotalGains(totalGainsNew);
              }
              setTotalGainsDetails(x.data.total_gains);
              setExchangePrices(x.data.exchange_prices);
              setTotalGainsLoading(false);
            });

          dashboardAPI.getCryptoInvestSetttings(user).then((x) => {
            setCryptoSettings(x.data);
          });
        }
      })();
    } else {
      setTotalGainsLoading(true);
    }
  }, [user, isFocused]);
  useEffect(() => {
    if (
      exchangePrices !== null &&
      totalGainsDetails !== null &&
      cryptoSettings !== null
    ) {
      let cryptcards2 = getCardOrderAndAmounts(true);
      setCryptoCards(cryptcards2);

      cryptcards2 = getCardOrderAndAmounts(false);
      setCryptoCardsNotInvesting(cryptcards2);
    }
  }, [cryptoSettings, exchangePrices, totalGainsDetails]);

  const chartConfig = {
    backgroundGradientFrom: '#1E2923',
    backgroundGradientFromOpacity: 0,
    backgroundGradientTo: '#08130D',
    backgroundGradientToOpacity: 0.5,
    color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
    strokeWidth: 2, // optional, default 3
    barPercentage: 0.5,
    useShadowColorFromDataset: false, // optional
  };

  const getCardOrderAndAmounts = (investing) => {
    const newData = [];
    const cryptos = dashboardAPI.getCryptosFromMarketList(marketApps);
    if (cryptoSettings === null) {
      return [];
    }

    for (let i = 0; i < cryptos.length; i++) {
      if (investing) {
        if (cryptoSettings[cryptos[i]] === 'dont_invest') {
          continue;
        }
      } else {
        if (cryptoSettings[cryptos[i]] !== 'dont_invest') {
          continue;
        }
      }

      let total_gains = 0.0;
      let exchange_prices = 0.0;
      if (cryptos[i] === 'SHIB') {
        total_gains = totalGainsDetails['SHIB'];
        exchange_prices = exchangePrices['SHIB'];
      }
      if (cryptos[i] === 'DOT') {
        total_gains = totalGainsDetails['DOT'];
        exchange_prices = exchangePrices['DOT'];
      }
      if (cryptos[i] === 'ADA') {
        total_gains = totalGainsDetails['ADA'];
        exchange_prices = exchangePrices['ADA'];
      }
      if (cryptos[i] === 'MATIC') {
        total_gains = totalGainsDetails['MATIC'];
        exchange_prices = exchangePrices['MATIC'];
      }
      if (cryptos[i] === 'XRP') {
        total_gains = totalGainsDetails['XRP'];
        exchange_prices = exchangePrices['XRP'];
      }
      if (cryptos[i] === 'SOL') {
        total_gains = totalGainsDetails['SOL'];
        exchange_prices = exchangePrices['SOL'];
      }
      if (cryptos[i] === 'AVAX') {
        total_gains = totalGainsDetails['AVAX'];
        exchange_prices = exchangePrices['AVAX'];
      }
      if (cryptos[i] === 'BTC') {
        total_gains = totalGainsDetails['BTC'];
        exchange_prices = exchangePrices['BTC'];
      }
      if (cryptos[i] === 'ETH') {
        total_gains = totalGainsDetails['ETH'];
        exchange_prices = exchangePrices['ETH'];
      }
      if (cryptos[i] === 'ETC') {
        total_gains = totalGainsDetails['ETC'];
        exchange_prices = exchangePrices['ETC'];
      }
      if (cryptos[i] === 'DOGE') {
        total_gains = totalGainsDetails['DOGE'];
        exchange_prices = exchangePrices['DOGE'];
      }
      if (cryptos[i] === 'LINK') {
        total_gains = totalGainsDetails['LINK'];
        exchange_prices = exchangePrices['LINK'];
      }
      if (cryptos[i] === 'UNI') {
        total_gains = totalGainsDetails['UNI'];
        exchange_prices = exchangePrices['UNI'];
      }
      if (cryptos[i] === 'XLM') {
        total_gains = totalGainsDetails['XLM'];
        exchange_prices = exchangePrices['XLM'];
      }
      if (cryptos[i] === 'BCH') {
        total_gains = totalGainsDetails['BCH'];
        exchange_prices = exchangePrices['BCH'];
      }
      if (cryptos[i] === 'COMP') {
        total_gains = totalGainsDetails['COMP'];
        exchange_prices = exchangePrices['COMP'];
      }
      if (cryptos[i] === 'AAVE') {
        total_gains = totalGainsDetails['AAVE'];
        exchange_prices = exchangePrices['AAVE'];
      }
      if (cryptos[i] === 'LTC') {
        total_gains = totalGainsDetails['LTC'];
        exchange_prices = exchangePrices['LTC'];
      }
      if (cryptos[i] === 'XTZ') {
        total_gains = totalGainsDetails['XTZ'];
        exchange_prices = exchangePrices['XTZ'];
      }
      const amount = total_gains * exchange_prices;

      const item = {
        name: cryptos[i],
        amount: parseFloat(amount.toFixed(2)),
      };
      newData.push(item);
    }
    newData.sort((a, b) => a.amount < b.amount);
    return newData;
  };

  const getPlayPieChartData = (x) => {
    const newData = [];

    const cryptos_to_colors = {
      SHIB: 'rgba(131, 167, 234, 1)',
      XRP: 'rgba(131, 167, 234, 1)',
      MATIC: 'rgba(131, 167, 234, 1)',
      SOL: 'rgba(131, 167, 234, 1)',
      ADA: 'rgba(131, 167, 234, 1)',
      DOT: 'rgba(131, 167, 234, 1)',

      AVAX: 'rgba(131, 167, 234, 1)',
      BTC: 'rgba(256, 0, 134, 1)',
      BCH: 'rgba(200, 167, 234, 1)',
      ETH: 'rgba(100, 167, 234, 1)',
      DOGE: 'rgba(200, 67, 134, 1)',
      ETC: 'rgba(200, 211, 34, 1)',
      LINK: 'rgba(100, 211, 34, 1)',
      UNI: 'rgba(50, 110, 134, 1)',
      XLM: 'rgba(150, 110, 134, 1)',
      LTC: 'rgba(100, 165, 134, 1)',
      COMP: 'rgba(0, 50, 234, 1)',
      AAVE: 'rgba(100, 50, 234, 1)',
      XTZ: 'rgba(200, 50, 234, 1)',
    };

    const cryptos = dashboardAPI.getCryptosFromMarketList(marketApps);

    for (let i = 0; i < cryptos.length; i++) {
      let total_gains = 0.0;
      let exchange_prices = 0.0;
      if (cryptos[i] === 'SHIB') {
        total_gains = x.data.total_gains.SHIB;
        exchange_prices = x.data.exchange_prices.SHIB;
      }
      if (cryptos[i] === 'SOL') {
        total_gains = x.data.total_gains.SOL;
        exchange_prices = x.data.exchange_prices.SOL;
      }
      if (cryptos[i] === 'DOT') {
        total_gains = x.data.total_gains.DOT;
        exchange_prices = x.data.exchange_prices.DOT;
      }
      if (cryptos[i] === 'ADA') {
        total_gains = x.data.total_gains.ADA;
        exchange_prices = x.data.exchange_prices.ADA;
      }
      if (cryptos[i] === 'SOL') {
        total_gains = x.data.total_gains.SOL;
        exchange_prices = x.data.exchange_prices.SOL;
      }
      if (cryptos[i] === 'MATIC') {
        total_gains = x.data.total_gains.MATIC;
        exchange_prices = x.data.exchange_prices.MATIC;
      }
      if (cryptos[i] === 'XRP') {
        total_gains = x.data.total_gains.XRP;
        exchange_prices = x.data.exchange_prices.XRP;
      }
      if (cryptos[i] === 'AVAX') {
        total_gains = x.data.total_gains.AVAX;
        exchange_prices = x.data.exchange_prices.AVAX;
      }
      if (cryptos[i] === 'BTC') {
        total_gains = x.data.total_gains.BTC;
        exchange_prices = x.data.exchange_prices.BTC;
      }
      if (cryptos[i] === 'ETH') {
        total_gains = x.data.total_gains.ETH;
        exchange_prices = x.data.exchange_prices.ETH;
      }
      if (cryptos[i] === 'ETC') {
        total_gains = x.data.total_gains.ETC;
        exchange_prices = x.data.exchange_prices.ETC;
      }
      if (cryptos[i] === 'DOGE') {
        total_gains = x.data.total_gains.DOGE;
        exchange_prices = x.data.exchange_prices.DOGE;
      }
      if (cryptos[i] === 'LINK') {
        total_gains = x.data.total_gains.LINK;
        exchange_prices = x.data.exchange_prices.LINK;
      }
      if (cryptos[i] === 'UNI') {
        total_gains = x.data.total_gains.UNI;
        exchange_prices = x.data.exchange_prices.UNI;
      }
      if (cryptos[i] === 'XLM') {
        total_gains = x.data.total_gains.XLM;
        exchange_prices = x.data.exchange_prices.XLM;
      }
      if (cryptos[i] === 'BCH') {
        total_gains = x.data.total_gains.BCH;
        exchange_prices = x.data.exchange_prices.BCH;
      }
      if (cryptos[i] === 'COMP') {
        total_gains = x.data.total_gains.COMP;
        exchange_prices = x.data.exchange_prices.COMP;
      }
      if (cryptos[i] === 'AAVE') {
        total_gains = x.data.total_gains.AAVE;
        exchange_prices = x.data.exchange_prices.AAVE;
      }
      if (cryptos[i] === 'LTC') {
        total_gains = x.data.total_gains.LTC;
        exchange_prices = x.data.exchange_prices.LTC;
      }
      if (cryptos[i] === 'XTZ') {
        total_gains = x.data.total_gains.XTZ;
        exchange_prices = x.data.exchange_prices.XTZ;
      }
      const amount = total_gains * exchange_prices;
      if (amount > 0.0) {
        const item = {
          name: '$ ' + cryptos[i],
          population: parseFloat(amount.toFixed(2)),
          color: cryptos_to_colors[cryptos[i]],
          legendFontColor: '#7F7F7F',
          legendFontSize: 15,
        };
        newData.push(item);
      }
    }
    newData.sort((a, b) => a.population < b.population);

    return newData;
  };

  const getLapsedFunds = () => {
    setLapsedFundsUsd(null);
    dashboardAPI.getLapsedFunds(user).then((x) => {
      // console.log('lapsedboiiii', x.data);
      //
      // total_in_use_cash_mxn: 5875.89
      //
      // total_in_use_cash_usd: 11434.1
      //
      // total_lapsed_cash_mxn: 0
      //
      // total_lapsed_cash_usd: 6499.64
      //
      // total_open_cash_mxn: 0
      //
      // total_open_cash_usd: 3190.87
    });
  };

  const handleTimePeriodSelect = (timePeriod) => {
    setTotalGains(0.0);
    setTotalGainsDetails(null);
    setPlaysPieChart(null);
    setClosedPlays(0);
    setCanceledPlays(0);
    setOpenedPlays(0);
    setTotalGainsLoading(true);
    setRoiGaugeValue(0);
    setAvgDuration(0);
    dashboardAPI.getTotalGainsForTimePeriod(user, timePeriod).then((x) => {
      setCanceledPlays(x.data.play_summary.canceled_plays);
      setOpenedPlays(x.data.play_summary.opened_plays);
      setClosedPlays(x.data.play_summary.closed_plays);
      setRoiGaugeValue(x.data.play_summary.closed_plays_roi.toFixed(1));
      setAvgDuration(x.data.play_summary.closed_plays_duration);
      setTotalGainsLoading(false);

      let pieChart = getPlayPieChartData(x);

      setPlaysPieChart(pieChart);

      let cryptcards2 = getCardOrderAndAmounts(true);
      setCryptoCards(cryptcards2);

      cryptcards2 = getCardOrderAndAmounts(false);
      setCryptoCardsNotInvesting(cryptcards2);

      let savingsNew =
        parseFloat(
          (
            parseFloat(x.data.total_gains.ETH_SAVINGS) *
            x.data.exchange_prices.ETH
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.DOGE_SAVINGS) *
            x.data.exchange_prices.DOGE
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.BTC_SAVINGS) *
            x.data.exchange_prices.BTC
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.BCH_SAVINGS) *
            x.data.exchange_prices.BCH
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.XRP_SAVINGS) *
            x.data.exchange_prices.XRP
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.MATIC_SAVINGS) *
            x.data.exchange_prices.MATIC
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.SOL_SAVINGS) *
            x.data.exchange_prices.SOL
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.ADA_SAVINGS) *
            x.data.exchange_prices.ADA
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.DOT_SAVINGS) *
            x.data.exchange_prices.DOT
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.SHIB_SAVINGS) *
            x.data.exchange_prices.SHIB
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.AVAX_SAVINGS) *
            x.data.exchange_prices.AVAX
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.LINK_SAVINGS) *
            x.data.exchange_prices.LINK
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.UNI_SAVINGS) *
            x.data.exchange_prices.UNI
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.ETC_SAVINGS) *
            x.data.exchange_prices.ETC
          ).toFixed(2),
        );

      let totalGainsNew =
        parseFloat(x.data.total_gains.cash) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.ETH) * x.data.exchange_prices.ETH
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.DOGE) * x.data.exchange_prices.DOGE
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.BTC) * x.data.exchange_prices.BTC
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.BCH) * x.data.exchange_prices.BCH
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.AVAX) * x.data.exchange_prices.AVAX
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.SHIB) * x.data.exchange_prices.SHIB
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.DOT) * x.data.exchange_prices.DOT
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.SOL) * x.data.exchange_prices.SOL
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.ADA) * x.data.exchange_prices.ADA
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.MATIC) * x.data.exchange_prices.MATIC
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.XRP) * x.data.exchange_prices.XRP
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.LINK) * x.data.exchange_prices.LINK
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.UNI) * x.data.exchange_prices.UNI
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.ETC) * x.data.exchange_prices.ETC
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.XLM) * x.data.exchange_prices.XLM
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.COMP) * x.data.exchange_prices.COMP
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.AAVE) * x.data.exchange_prices.AAVE
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.XTZ) * x.data.exchange_prices.XTZ
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.LTC) * x.data.exchange_prices.LTC
          ).toFixed(2),
        );
      if (isNaN(totalGainsNew)) {
        setTotalGains(0.0);
      } else {
        setTotalGains(totalGainsNew);
      }
      setTotalGainsDetails(x.data.total_gains);
      setGainsTimePeriod(timePeriod);
    });
  };

  const viewAddFunds = () => {
    navigation.navigate('AddFunds', {});
  };
  const viewManageFunds = () => {
    navigation.navigate('ManageFunds', {});
  };
  const viewEditManyPlays = () => {
    navigation.navigate('EditManyPlays', {});
  };

  const persistGlobalBetSettings = (setting) => {
    dashboardAPI.postPlaySettings(user, setting).then((x) => {
      refreshMarketApps(user);
      setPlaySettingsSheet(!showPlaySettingsSheet);
    });
  };

  const showAddMoneyAlert = () => {
    if (totalOpenFunds + totalInUseFundsBear + totalInUseFundsBull === 0) {
      return true;
    }
    return false;
  };

  const showNoFundsAlert = () => {
    if (totalOpenFunds + totalInUseFundsBear + totalInUseFundsBull === 0) {
      return false;
    }

    if (
      (playSettings === 'BULL' || playSettings === 'BOTH') &&
      totalOpenFundsBull === 0
    ) {
      return true;
    }
    if (
      (playSettings === 'BEAR' || playSettings === 'BOTH') &&
      totalOpenFunds - totalOpenFundsBull === 0
    ) {
      return true;
    }
    return false;
  };

  const showRobinHoodSessionIsGoingToExpire = () => {
    if (!marketApps.includes('robinhood_stocks')) {
      return false;
    }
    if (rhSessionExpirationDate === null) {
      return false;
    }
    const time = new Date();
    if (rhSessionExpirationDate < time) {
      return false;
    }
    const dateOffset = 3 * 24 * 60 * 60 * 1000;
    time.setTime(time.getTime() - dateOffset);
    if (rhSessionExpirationDate < time) {
      return true;
    }
    return false;
  };

  const showExpiredRobinHoodSession = () => {
    if (!marketApps.includes('robinhood_stocks')) {
      return false;
    }
    if (rhSessionExpirationDate === null) {
      return false;
    }
    if (rhSessionExpirationDate < new Date()) {
      return true;
    }
    return false;
  };

  const showNotInvestingCryptoList = () => {
    if (cryptoSettings === null) {
      return false;
    }
    const cryptos = dashboardAPI.getCryptosFromMarketList(marketApps);
    for (let index2 = 0; index2 < cryptos.length; index2++) {
      const property = cryptos[index2];
      if (cryptoSettings[property] === 'dont_invest') {
        return true;
      }
    }
    return false;
  };

  const showSelectCryptoAlert = () => {
    if (cryptoSettings === null) {
      return false;
    }
    const cryptos = dashboardAPI.getCryptosFromMarketList(marketApps);
    for (let index2 = 0; index2 < cryptos.length; index2++) {
      const property = cryptos[index2];
      if (cryptoSettings[property] !== 'dont_invest') {
        return false;
      }
    }
    return true;
  };

  let width = Dimensions.get('window').width;
  let height = Dimensions.get('window').height;
  let heightScreen = Dimensions.get('screen').height;

  return (
    <SafeAreaView style={{ height: '100%', width: '100%' }}>
      {cryptoSettings !== null && (
        <ScrollView>
          {showAddMoneyAlert() && (
            <TouchableOpacity onPress={() => viewAddFunds()}>
              <Alert mx="$2.5" action="info" variant="outline">
                <AlertIcon as={InfoIcon} mr="$3" />
                <AlertText>
                  No Funds Assigned To Bot. Click Here To Invest
                </AlertText>
              </Alert>
            </TouchableOpacity>
          )}

          {showNoFundsAlert() && (
            <TouchableOpacity onPress={() => viewAddFunds()}>
              <Alert mx="$2.5" action="warning" variant="outline">
                <AlertIcon as={InfoIcon} mr="$3" />
                <AlertText>
                  All Funds are in use. Invest more money to create new Plays
                </AlertText>
              </Alert>
            </TouchableOpacity>
          )}

          {showExpiredRobinHoodSession() && (
            <TouchableOpacity
              onPress={() => navigation.navigate('RobinhoodLogin')}
            >
              <Alert mx="$2.5" action="error" variant="outline">
                <AlertIcon as={InfoIcon} mr="$3" />
                <AlertText>
                  Robinhood Session is expired. CryptoPilot.app is not able to
                  create new plays. Sign in again to refresh session
                </AlertText>
              </Alert>
            </TouchableOpacity>
          )}
          {showRobinHoodSessionIsGoingToExpire() && (
            <TouchableOpacity
              onPress={() => navigation.navigate('RobinhoodLogin')}
            >
              <Alert mx="$2.5" action="warning" variant="outline">
                <AlertIcon as={InfoIcon} mr="$3" />
                <AlertText>
                  Robinhood Session is going to expire on{' '}
                  {rhSessionExpirationDate.toLocaleDateString()}. Sign in again
                  to refresh session.
                </AlertText>
              </Alert>
            </TouchableOpacity>
          )}
          {viewDetails && (
            <>
              <View style={{ height: height * 0.45, width: width * 0.97 }}>
                {((playsPieChart !== null && playsPieChart.length > 0) ||
                  playsPieChart === null) && (
                  <Heading ml={10}>Gains Per Crypto In USD</Heading>
                )}
                <ScrollView horizontal={true}>
                  <Center style={styles.card_scroll} ml={3}>
                    {playsPieChart !== null && playsPieChart.length > 0 && (
                      <PieChart
                        data={playsPieChart}
                        width={420}
                        height={280}
                        chartConfig={chartConfig}
                        accessor={'population'}
                        backgroundColor={'transparent'}
                        absolute
                      />
                    )}
                    {playsPieChart !== null && playsPieChart.length === 0 && (
                      <Heading>No Completed Plays</Heading>
                    )}
                    {playsPieChart === null && <Loading />}
                  </Center>
                </ScrollView>
              </View>

              <>
                <Heading ml={20}>Metrics</Heading>
                <ScrollView
                  horizontal={true}
                  contentContainerStyle={{
                    paddingHorizontal: 15,
                    paddingBottom: 2,
                    paddingTop: 2,
                    marginBottom: 2,
                    height: heightScreen / 8,
                  }}
                >
                  <Tooltip
                    isOpen={totalGainsToolTipState}
                    placement="top"
                    onClose={() => setTotalGainsToolTipState(false)}
                    onOpen={() => setTotalGainsToolTipState(true)}
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity {...triggerProps}>
                          <Box
                            style={styles.card_metric}
                            borderColor={
                              totalGainsToolTipState === false
                                ? 'black'
                                : '$primary500'
                            }
                          >
                            <Heading
                              size={'sm'}
                              color={
                                totalGainsToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              $
                              {totalGains > 1000
                                ? (totalGains / 1000).toFixed(1) + 'K'
                                : totalGains.toFixed(2)}
                              {' USD'}
                            </Heading>
                            <Text
                              color={
                                totalGainsToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              Gains
                            </Text>

                            {totalGainsLoading && <Spinner size="small" />}
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  >
                    <TooltipContent>
                      <TooltipText>Amount won from Plays</TooltipText>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip
                    isOpen={completedPlaysToolTipState}
                    onClose={() => setCompletedPlaysToolTipState(false)}
                    onOpen={() => setCompletedPlaysToolTipState(true)}
                    placement="top"
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity {...triggerProps}>
                          <Box
                            style={styles.card_metric}
                            borderColor={
                              completedPlaysToolTipState === false
                                ? 'black'
                                : '$primary500'
                            }
                          >
                            <Heading
                              size={'sm'}
                              color={
                                completedPlaysToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              {closedPlays > 1000
                                ? (closedPlays / 1000).toFixed(1) + 'K'
                                : closedPlays.toFixed(0)}{' '}
                            </Heading>
                            <Text
                              color={
                                completedPlaysToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              Plays Completed
                            </Text>
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  >
                    <TooltipContent>
                      <TooltipText>Total of Successful Plays</TooltipText>
                    </TooltipContent>
                  </Tooltip>

                  {canceledPlays > 0 && (
                    <Tooltip
                      placement="top"
                      isOpen={winLossRatioToolTipState}
                      onClose={() => setWinLossRatioToolTipState(false)}
                      onOpen={() => setWinLossRatioToolTipState(true)}
                      trigger={(triggerProps) => {
                        return (
                          <TouchableOpacity {...triggerProps}>
                            <Box
                              style={styles.card_metric}
                              borderColor={
                                winLossRatioToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                            >
                              <Heading
                                size={'sm'}
                                color={
                                  winLossRatioToolTipState === false
                                    ? 'black'
                                    : '$primary500'
                                }
                                ml={3}
                                mr={3}
                              >
                                {(closedPlays / canceledPlays).toFixed(2)}
                              </Heading>
                              <Text
                                color={
                                  winLossRatioToolTipState === false
                                    ? 'black'
                                    : '$primary500'
                                }
                                ml={3}
                                mr={3}
                              >
                                W/L Ratio
                              </Text>
                            </Box>
                          </TouchableOpacity>
                        );
                      }}
                    >
                      <TooltipContent>
                        <TooltipText>
                          Successful Plays / Unsuccessful Plays
                        </TooltipText>
                      </TooltipContent>
                    </Tooltip>
                  )}

                  <Tooltip
                    isOpen={ROIToolTipState}
                    placement="top left"
                    onClose={() => setROIToolTipState(false)}
                    onOpen={() => setROIToolTipState(true)}
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity {...triggerProps}>
                          <Box
                            style={styles.card_metric}
                            borderColor={
                              ROIToolTipState === false
                                ? 'black'
                                : '$primary500'
                            }
                          >
                            <Heading
                              size={'sm'}
                              color={
                                ROIToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              %{roiGaugeValue}
                            </Heading>
                            <Text
                              color={
                                ROIToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              ROI Per Play
                            </Text>
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  >
                    <TooltipContent>
                      <TooltipText>Return On Investment</TooltipText>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip
                    placement="top left"
                    isOpen={playDurationToolTipState}
                    onClose={() => setPlayDurationToolTipState(false)}
                    onOpen={() => setPlayDurationToolTipState(true)}
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity {...triggerProps}>
                          <Box
                            style={styles.card_metric}
                            borderColor={
                              playDurationToolTipState === false
                                ? 'black'
                                : '$primary500'
                            }
                          >
                            <Center>
                              <DurationAvatar
                                ml={3}
                                mr={3}
                                color={
                                  playDurationToolTipState === false
                                    ? 'black'
                                    : '$primary500'
                                }
                                horizontal
                                duration={avgDuration}
                              />
                            </Center>

                            <Text
                              color={
                                playDurationToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              Avg Play Duration
                            </Text>
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  >
                    <TooltipContent>
                      <TooltipText>Play Completion Time</TooltipText>
                    </TooltipContent>
                  </Tooltip>
                </ScrollView>
              </>
            </>
          )}
          {!viewDetails ? (
            <TouchableOpacity onPress={() => setViewDetails(!viewDetails)}>
              <HStack style={styles.gains} space={'4xl'}>
                <Box>
                  <Heading>Gains</Heading>

                  <Heading>
                    $
                    {totalGains > 1000
                      ? (totalGains / 1000).toFixed(1) + 'K'
                      : totalGains.toFixed(2)}
                    {' USD'}
                  </Heading>
                  {totalGainsLoading && <Spinner size="small" />}
                </Box>
                <Box>
                  <Icon mt={10} as={ChevronDownIcon} size="lg" />
                </Box>
              </HStack>
            </TouchableOpacity>
          ) : (
            <Button
              mb={5}
              ml={5}
              mr={5}
              onPress={() => setViewDetails(!viewDetails)}
            >
              <ButtonText>COLLAPSE</ButtonText>
            </Button>
          )}

          <HStack space={'4xl'} justifyContent="center" alignItems="center">
            <Button
              onPress={() => handleTimePeriodSelect('1H')}
              variant={gainsTimePeriod === '1H' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 1H </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('1D')}
              variant={gainsTimePeriod === '1D' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 1D </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('1W')}
              variant={gainsTimePeriod === '1W' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 1W </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('1M')}
              variant={gainsTimePeriod === '1M' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 1M </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('3M')}
              variant={gainsTimePeriod === '3M' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 3M </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('ALL_TIME')}
              variant={gainsTimePeriod === 'ALL_TIME' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText>ALL TIME</ButtonText>
            </Button>
          </HStack>
          {/*<Button variant={'outline'}>*/}
          {/*    <ButtonText>ALL TIME</ButtonText>*/}
          {/*</Button>*/}

          <Box w={'$95'} style={styles.card_right_half}>
            <Heading>Open Funds</Heading>
            <HStack>
              {playSettings !== 'BEAR' && (
                <Box>
                  <Text>Bull Plays 📈</Text>
                  <Progress value={openBullFundsPercentage} w={140} size="md">
                    <ProgressFilledTrack bg={gaugeBullFundsColor} />
                  </Progress>
                  <Text>
                    {totalOpenFundsBull}/
                    {totalOpenFundsBull + totalInUseFundsBull}
                  </Text>
                  {displayBullFundWarningBadge && (
                    <Badge
                      size="md"
                      variant="solid"
                      borderRadius="$2xl"
                      action={colorBullFundWarningBadge}
                    >
                      <BadgeText>{titleBullFundWarningBadge}</BadgeText>
                    </Badge>
                  )}
                </Box>
              )}
              {playSettings !== 'BULL' && (
                <Box>
                  <Text>Bear Plays 📉</Text>
                  <Progress value={openBearFundsPercentage} w={140} size="md">
                    <ProgressFilledTrack bg={gaugeBearFundsColor} />
                  </Progress>
                  {displayBearFundWarningBadge && (
                    <Badge
                      size="md"
                      variant="solid"
                      borderRadius="$2xl"
                      action={colorBearFundWarningBadge}
                    >
                      <BadgeText>{titleBearFundWarningBadge}</BadgeText>
                    </Badge>
                  )}
                  <Text>
                    {totalOpenFunds - totalOpenFundsBull}/
                    {totalOpenFunds - totalOpenFundsBull + totalInUseFundsBear}
                  </Text>
                </Box>
              )}
            </HStack>
            <Center mb={10}>
              <ButtonGroup>
                <Button size={'lg'} variant={'link'} onPress={viewManageFunds}>
                  <ButtonText>Manage Funds</ButtonText>
                </Button>
                <Button size={'lg'} variant={'outline'} onPress={viewAddFunds}>
                  <ButtonText>Add Funds</ButtonText>
                </Button>
              </ButtonGroup>
            </Center>
            <Heading>Invest Mode</Heading>
            {cryptoSettings !== null ? (
              <Center>
                <Button
                  variant={'outline'}
                  onPress={() => setPlaySettingsSheet(!showPlaySettingsSheet)}
                  size={'lg'}
                >
                  <ButtonText>
                    {dashboardAPI.investSettingsExplanations[playSettings]}
                  </ButtonText>
                </Button>
              </Center>
            ) : null}
          </Box>

          {cryptoSettings !== null && (
            <>
              <Heading mt={5} ml={10} size={'sm'}>
                Cryptos To Invest
              </Heading>
              {showSelectCryptoAlert() && (
                <Alert mx="$2.5" action="warning" variant="outline">
                  <AlertIcon as={InfoIcon} mr="$3" />
                  <AlertText>
                    Currently Not In Investing Any Cryptos. Select And Configure
                    Below
                  </AlertText>
                </Alert>
              )}

              <FlatList
                horizontal
                data={cryptoCards}
                renderItem={({ item }) => {
                  return (
                    <CryptoCard
                      coinName={item.name}
                      cryptoSettings={cryptoSettings}
                      key={item.name}
                      amountEarned={item.amount}
                      dateRange={gainsTimePeriod}
                    />
                  );
                }}
              />
              {showNotInvestingCryptoList() && (
                <>
                  <Heading ml={10} size={'sm'}>
                    Cryptos To Not Invest
                  </Heading>

                  <FlatList
                    horizontal
                    data={cryptoCardsNotInvesting}
                    renderItem={({ item }) => {
                      return (
                        <CryptoCard
                          coinName={item.name}
                          cryptoSettings={cryptoSettings}
                          key={item.name}
                          amountEarned={item.amount}
                          dateRange={gainsTimePeriod}
                        />
                      );
                    }}
                  />
                </>
              )}
            </>
          )}
        </ScrollView>
      )}

      <Actionsheet
        isOpen={showPlaySettingsSheet}
        onClose={() => setPlaySettingsSheet(!showPlaySettingsSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Play Settings
          </ActionsheetSectionHeaderText>

          <Button
            onPress={() => persistGlobalBetSettings('BULL')}
            mt={20}
            borderRadius="$full"
            variant={playSettings === 'BULL' ? 'solid' : 'outline'}
            action={'secondary'}
          >
            <ButtonText>
              {dashboardAPI.investSettingsExplanations['BULL']}
            </ButtonText>
          </Button>

          <Button
            onPress={() => persistGlobalBetSettings('BEAR')}
            mt={10}
            borderRadius="$full"
            variant={playSettings === 'BEAR' ? 'solid' : 'outline'}
            action={'secondary'}
          >
            <ButtonText>
              {dashboardAPI.investSettingsExplanations['BEAR']}
            </ButtonText>
          </Button>

          <Button
            onPress={() => persistGlobalBetSettings('BOTH')}
            mt={10}
            borderRadius="$full"
            variant={playSettings === 'BOTH' ? 'solid' : 'outline'}
            action={'secondary'}
          >
            <ButtonText>
              BOTH - {dashboardAPI.investSettingsExplanations['BOTH']}
            </ButtonText>
          </Button>

          <Button
            onPress={() => persistGlobalBetSettings('NONE')}
            mt={10}
            borderRadius="$full"
            variant={playSettings === 'NONE' ? 'solid' : 'outline'}
            action={'secondary'}
          >
            <ButtonText>
              {dashboardAPI.investSettingsExplanations['NONE']}
            </ButtonText>
          </Button>
        </ActionsheetContent>
      </Actionsheet>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  button: {
    // flex: 1,
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,

    paddingTop: 10,
    paddingBottom: 8,
  },
  gains: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    padding: 5,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 3,
    marginBottom: 5,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#2A7948',
  },
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 3,
    paddingBottom: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
  card_scroll: {
    // flex: 1,
    margin: 10,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
    padding: 4,
  },
  card_left_half: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
  card_right_half: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 10,
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
  card_metric: {
    // flex: 1,
    //alignItems: 'center',
    //alignItems: 'center',
    // justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 5,
    marginLeft: 5,
    marginTop: 15,
    marginBottom: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 2,
    backgroundColor: '#fff',
    height: '80%',
  },
});
