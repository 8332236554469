import axios from 'axios';

import { BASE_URL, ENCRYPT_KEY, ENCRYPT_KEY_IV } from '@env';
import CryptoJS from 'crypto-js';

const CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION = {
  BTC: 2,
  ETH: 2,
  DOGE: 6,
  AVAX: 2,
  BCH: 2,
  UNI: 3,
  LINK: 2,
  XLM: 6,
  ETC: 2,
  LTC: 2,
  COMP: 2,
  AAVE: 2,
  XTZ: 3,
  XRP: 3,
  MATIC: 3,
  SOL: 3,
  ADA: 3,
  DOT: 3,
  SHIB: 8,
  VFS: 2,
  SMCI: 2,
  IAC: 2,
  NOW: 2,
  AAL: 2,
  HD: 2,
  WDAY: 2,
  CHWY: 2,
  META: 2,
  NFLX: 2,
  DAL: 2,
  NIO: 2,
  CRM: 2,
  AAPL: 2,
  MSFT: 2,
  HOOD: 2,
  RBLX: 2,
  RIVN: 2,
  KOSS: 2,
  GME: 2,
  XOM: 2,
  HLX: 2,
  TSM: 2,
  NVDA: 2,
  AI: 2,
  SAP: 2,
  AMD: 2,
  QCOM: 2,
  PANW: 2,
  EQIX: 2,
  GOOG: 2,
  COP: 2,
  UUUU: 2,
  NET: 2,
  MANH: 2,
  FROG: 2,
  PD: 2,
  FTHM: 2,
  WMT: 2,
  LLY: 2,
  CRSP: 2,
  AUST: 2,
  GXO: 2,
  AIT: 2,
  MU: 2,
  SNOW: 2,
  TEAM: 2,
  DOCN: 2,
  ZS: 2,
  OKTA: 2,
  SQ: 2,
  TALO: 2,
  INFY: 2,
  CDNS: 2,
  SNPS: 2,
  AMX: 2,
  AMAT: 2,
  CEG: 2,
  PLUG: 2,
  LIN: 2,
  BLDP: 2,
  IREN: 2,
  ADBE: 2,
  CLSK: 2,
  MARA: 2,
  RIOT: 2,
  MSTR: 2,
  CLCO: 2,
  JKS: 2,
};

const CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION = {
  BTC: 8,
  ETH: 8,
  DOGE: 2,
  AVAX: 4,
  BCH: 8,
  UNI: 3,
  LINK: 3,
  XLM: 0,
  ETC: 6,
  LTC: 6,
  COMP: 4,
  AAVE: 4,
  XTZ: 3,
  XRP: 6,
  MATIC: 6,
  SOL: 6,
  ADA: 6,
  DOT: 6,
  SHIB: 0,

  VFS: 6,
  SMCI: 6,
  IAC: 6,
  NOW: 6,
  AAL: 6,
  HD: 6,
  WDAY: 6,
  CHWY: 6,
  META: 6,
  NFLX: 6,
  DAL: 6,
  NIO: 6,
  CRM: 6,
  AAPL: 6,
  MSFT: 6,
  HOOD: 6,
  RBLX: 6,
  RIVN: 6,
  KOSS: 6,
  GME: 6,
  XOM: 6,
  HLX: 6,
  TSM: 6,
  NVDA: 6,
  AI: 6,
  SAP: 6,
  AMD: 6,
  QCOM: 6,
  PANW: 6,
  EQIX: 6,
  GOOG: 6,
  COP: 6,
  UUUU: 6,
  NET: 6,
  MANH: 6,
  FROG: 6,
  PD: 6,
  FTHM: 6,
  WMT: 6,
  LLY: 6,
  CRSP: 6,
  AUST: 6,
  GXO: 6,
  AIT: 6,
  MU: 6,
  SNOW: 6,
  TEAM: 6,
  DOCN: 6,
  ZS: 6,
  OKTA: 6,
  SQ: 6,
  TALO: 6,
  INFY: 6,
  CDNS: 6,
  SNPS: 6,
  AMX: 6,
  AMAT: 6,
  CEG: 6,
  PLUG: 6,
  LIN: 6,
  BLDP: 6,
  IREN: 6,
  ADBE: 6,
  CLSK: 6,
  MARA: 6,
  RIOT: 6,
  MSTR: 6,
  CLCO: 6,
  JKS: 6,
};

const cryptoIconMap = {
  ETH: require('./assets/eth.png'),
  DOGE: require('./assets/doge.png'),
  BTC: require('./assets/btc.png'),
  AVAX: require('./assets/avax.png'),
  XLM: require('./assets/xlm.png'),
  BCH: require('./assets/bch.png'),
  LINK: require('./assets/link.png'),
  UNI: require('./assets/uni.png'),
  ETC: require('./assets/etc.png'),
  XTZ: require('./assets/xtz.png'),
  COMP: require('./assets/comp.png'),
  AAVE: require('./assets/aave.png'),
  LTC: require('./assets/ltc.png'),
  default: require('./assets/grin.png'),
  XRP: require('./assets/xrp.png'),
  MATIC: require('./assets/matic.png'),
  SOL: require('./assets/sol.png'),
  ADA: require('./assets/ada.png'),
  DOT: require('./assets/dot.png'),
  SHIB: require('./assets/shib.png'),
  VFS: require('./assets/factory-sm.png'),
  SMCI: require('./assets/factory-sm.png'),
  IAC: require('./assets/factory-sm.png'),
  NOW: require('./assets/factory-sm.png'),
  AAL: require('./assets/factory-sm.png'),
  HD: require('./assets/factory-sm.png'),
  WDAY: require('./assets/factory-sm.png'),
  CHWY: require('./assets/factory-sm.png'),
  META: require('./assets/factory-sm.png'),
  NFLX: require('./assets/factory-sm.png'),
  DAL: require('./assets/factory-sm.png'),
  NIO: require('./assets/factory-sm.png'),
  CRM: require('./assets/factory-sm.png'),
  AAPL: require('./assets/factory-sm.png'),
  MSFT: require('./assets/factory-sm.png'),
  HOOD: require('./assets/factory-sm.png'),
  RBLX: require('./assets/factory-sm.png'),
  RIVN: require('./assets/factory-sm.png'),
  KOSS: require('./assets/factory-sm.png'),
  GME: require('./assets/factory-sm.png'),
  XOM: require('./assets/factory-sm.png'),
  HLX: require('./assets/factory-sm.png'),
  TSM: require('./assets/factory-sm.png'),
  NVDA: require('./assets/factory-sm.png'),
  AI: require('./assets/factory-sm.png'),
  SAP: require('./assets/factory-sm.png'),
  AMD: require('./assets/factory-sm.png'),
  QCOM: require('./assets/factory-sm.png'),
  PANW: require('./assets/factory-sm.png'),
  EQIX: require('./assets/factory-sm.png'),
  GOOG: require('./assets/factory-sm.png'),
  COP: require('./assets/factory-sm.png'),
  UUUU: require('./assets/factory-sm.png'),
  NET: require('./assets/factory-sm.png'),
  MANH: require('./assets/factory-sm.png'),
  FROG: require('./assets/factory-sm.png'),
  PD: require('./assets/factory-sm.png'),
  FTHM: require('./assets/factory-sm.png'),
  WMT: require('./assets/factory-sm.png'),
  LLY: require('./assets/factory-sm.png'),
  CRSP: require('./assets/factory-sm.png'),
  AUST: require('./assets/factory-sm.png'),
  GXO: require('./assets/factory-sm.png'),
  AIT: require('./assets/factory-sm.png'),
  MU: require('./assets/factory-sm.png'),
  SNOW: require('./assets/factory-sm.png'),
  TEAM: require('./assets/factory-sm.png'),
  DOCN: require('./assets/factory-sm.png'),
  ZS: require('./assets/factory-sm.png'),
  OKTA: require('./assets/factory-sm.png'),
  SQ: require('./assets/factory-sm.png'),
  TALO: require('./assets/factory-sm.png'),
  INFY: require('./assets/factory-sm.png'),
  CDNS: require('./assets/factory-sm.png'),
  SNPS: require('./assets/factory-sm.png'),
  AMX: require('./assets/factory-sm.png'),
  AMAT: require('./assets/factory-sm.png'),
  CEG: require('./assets/factory-sm.png'),
  PLUG: require('./assets/factory-sm.png'),
  LIN: require('./assets/factory-sm.png'),
  BLDP: require('./assets/factory-sm.png'),
  IREN: require('./assets/factory-sm.png'),
  ADBE: require('./assets/factory-sm.png'),
  CLSK: require('./assets/factory-sm.png'),
  MARA: require('./assets/factory-sm.png'),
  RIOT: require('./assets/factory-sm.png'),
  MSTR: require('./assets/factory-sm.png'),
  CLCO: require('./assets/factory-sm.png'),
  JKS: require('./assets/factory-sm.png'),
};

const cryptoSmallIconMap = {
  ETH: require('./assets/eth-sm.png'),
  DOGE: require('./assets/doge-sm.png'),
  BTC: require('./assets/btc-sm.png'),
  AVAX: require('./assets/avax-sm.png'),
  XLM: require('./assets/xlm-sm.png'),
  BCH: require('./assets/bch-sm.png'),
  LINK: require('./assets/link-sm.png'),
  UNI: require('./assets/uni-sm.png'),
  ETC: require('./assets/etc-sm.png'),
  XTZ: require('./assets/xtz-sm.png'),
  COMP: require('./assets/comp-sm.png'),
  AAVE: require('./assets/aave-sm.png'),
  LTC: require('./assets/ltc-sm.png'),
  default: require('./assets/grin-sm.png'),
  XRP: require('./assets/xrp-sm.png'),
  MATIC: require('./assets/matic-sm.png'),
  SOL: require('./assets/sol-sm.png'),
  ADA: require('./assets/ada-sm.png'),
  DOT: require('./assets/dot-sm.png'),
  SHIB: require('./assets/shib-sm.png'),
  VFS: require('./assets/factory-sm.png'),
  SMCI: require('./assets/factory-sm.png'),
  IAC: require('./assets/factory-sm.png'),
  NOW: require('./assets/factory-sm.png'),
  AAL: require('./assets/factory-sm.png'),
  HD: require('./assets/factory-sm.png'),
  WDAY: require('./assets/factory-sm.png'),
  CHWY: require('./assets/factory-sm.png'),
  META: require('./assets/factory-sm.png'),
  NFLX: require('./assets/factory-sm.png'),
  DAL: require('./assets/factory-sm.png'),
  NIO: require('./assets/factory-sm.png'),
  CRM: require('./assets/factory-sm.png'),
  AAPL: require('./assets/factory-sm.png'),
  MSFT: require('./assets/factory-sm.png'),
  HOOD: require('./assets/factory-sm.png'),
  RBLX: require('./assets/factory-sm.png'),
  RIVN: require('./assets/factory-sm.png'),
  KOSS: require('./assets/factory-sm.png'),
  GME: require('./assets/factory-sm.png'),
  XOM: require('./assets/factory-sm.png'),
  HLX: require('./assets/factory-sm.png'),
  TSM: require('./assets/factory-sm.png'),
  NVDA: require('./assets/factory-sm.png'),
  AI: require('./assets/factory-sm.png'),
  SAP: require('./assets/factory-sm.png'),
  AMD: require('./assets/factory-sm.png'),
  QCOM: require('./assets/factory-sm.png'),
  PANW: require('./assets/factory-sm.png'),
  EQIX: require('./assets/factory-sm.png'),
  GOOG: require('./assets/factory-sm.png'),
  COP: require('./assets/factory-sm.png'),
  UUUU: require('./assets/factory-sm.png'),
  NET: require('./assets/factory-sm.png'),
  MANH: require('./assets/factory-sm.png'),
  FROG: require('./assets/factory-sm.png'),
  PD: require('./assets/factory-sm.png'),
  FTHM: require('./assets/factory-sm.png'),
  WMT: require('./assets/factory-sm.png'),
  LLY: require('./assets/factory-sm.png'),
  CRSP: require('./assets/factory-sm.png'),
  AUST: require('./assets/factory-sm.png'),
  GXO: require('./assets/factory-sm.png'),
  AIT: require('./assets/factory-sm.png'),
  MU: require('./assets/factory-sm.png'),
  SNOW: require('./assets/factory-sm.png'),
  TEAM: require('./assets/factory-sm.png'),
  DOCN: require('./assets/factory-sm.png'),
  ZS: require('./assets/factory-sm.png'),
  OKTA: require('./assets/factory-sm.png'),
  SQ: require('./assets/factory-sm.png'),
  TALO: require('./assets/factory-sm.png'),
  INFY: require('./assets/factory-sm.png'),
  CDNS: require('./assets/factory-sm.png'),
  SNPS: require('./assets/factory-sm.png'),
  AMX: require('./assets/factory-sm.png'),
  AMAT: require('./assets/factory-sm.png'),
  CEG: require('./assets/factory-sm.png'),
  PLUG: require('./assets/factory-sm.png'),
  LIN: require('./assets/factory-sm.png'),
  BLDP: require('./assets/factory-sm.png'),
  IREN: require('./assets/factory-sm.png'),
  ADBE: require('./assets/factory-sm.png'),
  CLSK: require('./assets/factory-sm.png'),
  MARA: require('./assets/factory-sm.png'),
  RIOT: require('./assets/factory-sm.png'),
  MSTR: require('./assets/factory-sm.png'),
  CLCO: require('./assets/factory-sm.png'),
  JKS: require('./assets/factory-sm.png'),
};

const investSettingsExplanations = {
  BOTH: 'SIDEWAYS MARKET🌊🌊🌊',
  BULL: 'BULL MARKET📈',
  BEAR: 'BEAR MARKET📉',
  NONE: 'DO NOT INVEST',
};

const CRYPTO_CONFIG = {
  names: [
    'BTC',
    'ETH',
    'DOGE',
    'AVAX',
    'BCH',
    'UNI',
    'LINK',
    'XLM',
    'ETC',
    'LTC',
    'COMP',
    'AAVE',
    'XTZ',
    'XRP',
    'MATIC',
    'SOL',
    'ADA',
    'DOT',
    'SHIB',
    'VFS',
    'SMCI',
    'IAC',
    'NOW',
    'AAL',
    'HD',
    'WDAY',
    'DAL',
    'CHWY',
    'META',
    'NFLX',
    'DAL',
    'NIO',
    'CRM',
    'AAPL',
    'MSFT',
    'HOOD',
    'RBLX',
    'RIVN',
    'KOSS',
    'GME',
    'XOM',
    'HLX',
    'TSM',
    'NVDA',
    'AI',
    'SAP',
    'AMD',
    'QCOM',
    'PANW',
    'EQIX',
    'GOOG',
    'COP',
    'UUUU',
    'NET',
    'MANH',
    'FROG',
    'PD',
    'FTHM',
    'WMT',
    'LLY',
    'CRSP',
    'AUST',
    'GXO',
    'AIT',
    'MU',
    'SNOW',
    'TEAM',
    'DOCN',
    'ZS',
    'OKTA',
    'SQ',
    'TALO',
    'INFY',
    'CDNS',
    'SNPS',
    'AMX',
    'AMAT',
    'CEG',
    'PLUG',
    'LIN',
    'BLDP',
    'IREN',
    'ADBE',
    'CLSK',
    'MARA',
    'RIOT',
    'MSTR',
    'CLCO',
    'JKS',
  ],
  fullNames: {
    BTC: 'Bitcoin',
    ETH: 'Ethereum',
    DOGE: 'Dogecoin',
    AVAX: 'Avalanche',
    BCH: 'Bitcoin Cash',
    UNI: 'Uniswap',
    LINK: 'Chainlink',
    XLM: 'Stellar Lumens',
    ETC: 'Ethereum Classic',
    LTC: 'Litecoin',
    COMP: 'Compound',
    AAVE: 'Aave',
    XTZ: 'Tezos',
    XRP: 'XRP',
    MATIC: 'Polygon',
    SOL: 'Solana',
    ADA: 'Cardano',
    DOT: 'Pokadot',
    SHIB: 'Shina Inu',
    VFS: 'VinFast Auto',
    SMCI: 'Super Micro Computer',
    IAC: 'IAC/InterActiveCorp',
    NOW: 'ServiceNow',
    AAL: 'American Airlines',
    HD: 'The Home Depot',
    WDAY: 'Workday',
    DAL: 'Delta Air Lines',
    CHWY: 'Chewy',
    META: 'Meta Platforms',
    NFLX: 'Netflix',
    NIO: 'NIO',
    CRM: 'Salesforce',
    AAPL: 'Apple',
    MSFT: 'Microsoft Corporation',
    HOOD: 'Robinhood Markets',
    RBLX: 'Roblox Corporation',
    RIVN: 'Rivian Automotive',
    KOSS: 'Koss Corporation',
    GME: 'GameStop Corp.',
    XOM: 'Exxon Mobil',
    HLX: 'Helix Energy',
    TSM: 'Taiwan Semiconductor Manufacturing Company',
    NVDA: 'NVIDIA',
    AI: 'C3.ai',
    SAP: 'SAP',
    AMD: 'Advanced Micro Devices',
    QCOM: 'Qualcomm',
    PANW: 'Palo Alto Networks',
    EQIX: 'Equinix',
    GOOG: 'Alphabet (Google)',
    COP: 'ConocoPhillips',
    UUUU: 'Energy Fuels',
    NET: 'Cloudflare',
    MANH: 'Manhattan Associates',
    FROG: 'Jfrog',
    PD: 'PagerDuty',
    FTHM: 'Fathom Digital Manufacturing',
    WMT: 'Walmart',
    LLY: 'Eli Lilly',
    CRSP: 'CRISPR Therapeutics',
    AUST: 'Austin Gold Corp',
    GXO: 'GXO Logistics',
    AIT: 'Applied Industrial Tech',
    MU: 'Micron Technology',
    SNOW: 'Snowflake Inc.',
    TEAM: 'Atlassian Corporation',
    DOCN: 'Digital Ocean.',
    ZS: 'Zscaler',
    OKTA: 'Okta',
    SQ: 'Block',
    TALO: 'Talos Energy',
    INFY: 'Infosys',
    CDNS: 'Cadence Design Systems',
    SNPS: 'Synopsys',
    AMX: 'America Movil',
    AMAT: 'Applied Materials',
    CEG: 'Constellation Energy',
    PLUG: 'Plug Power',
    LIN: 'Linde',
    BLDP: 'Ballard Power Systems',
    IREN: 'Iris Energy.',
    ADBE: 'Adobe',
    CLSK: 'CleanSpark',
    MARA: 'Marathon Digital Holdings',
    RIOT: 'Riot Platforms',
    MSTR: 'MicroStrategy',
    CLCO: 'Cool Company',
    JKS: 'JinkoSolar Holding',
  },
};
let RH_SUPPORTED_CRYPTO = new Set([
  'ETH',
  'BTC',
  'DOGE',
  'AVAX',
  'ETC',
  'XLM',
  'BCH',
  'LINK',
  'UNI',
  'LTC',
  'AAVE',
  'XTZ',
  'COMP',
  'SHIB',
]);
let RH_SUPPORTED_STOCKS = new Set([
  'VFS',
  'SMCI',
  'IAC',
  'NOW',
  'AAL',
  'HD',
  'WDAY',
  'DAL',
  'CHWY',
  'META',
  'NFLX',
  'DAL',
  'NIO',
  'CRM',
  'AAPL',
  'MSFT',
  'HOOD',
  'RBLX',
  'RIVN',
  'KOSS',
  'GME',
  'XOM',
  'HLX',
  'TSM',
  'NVDA',
  'AI',
  'SAP',
  'AMD',
  'QCOM',
  'PANW',
  'EQIX',
  'GOOG',
  'COP',
  'UUUU',
  'NET',
  'MANH',
  'FROG',
  'PD',
  'FTHM',
  'WMT',
  'LLY',
  'CRSP',
  'AUST',
  'GXO',
  'AIT',
  'MU',
  'SNOW',
  'TEAM',
  'DOCN',
  'ZS',
  'OKTA',
  'SQ',
  'TALO',
  'INFY',
  'CDNS',
  'SNPS',
  'AMX',
  'AMAT',
  'CEG',
  'PLUG',
  'LIN',
  'BLDP',
  'IREN',
  'ADBE',
  'CLSK',
  'MARA',
  'RIOT',
  'MSTR',
  'CLCO',
  'JKS',
]);
let BINANCE_SUPPORTED_CRYPTO = new Set([
  'DOGE',
  'ETH',
  'BTC',
  'LINK',
  'AVAX',
  'MATIC',
  'SOL',
  'DOT',
]);

let BITSO_USD_SUPPORTED_CRYPTO = new Set([
  'ETH',
  'BTC',
  'DOGE',
  'AVAX',
  'XLM',
  'BCH',
  'LINK',
  'UNI',
  'LTC',
  'AAVE',
  'COMP',
  'MATIC',
  'ADA',
  'SOL',
  'XRP',
  'DOT',
]);

function getCryptosFromMarket(markets) {
  if (markets === 'robinhood') {
    return Array.from(RH_SUPPORTED_CRYPTO);
  }
  if (markets === 'bitso') {
    return Array.from(BITSO_USD_SUPPORTED_CRYPTO);
  }
  if (markets === 'binance') {
    return Array.from(BINANCE_SUPPORTED_CRYPTO);
  }
  return CRYPTO_CONFIG.names;
}
function getCryptosFromMarketList(markets) {
  let cryptos = new Set([]);

  if (markets.includes('robinhood_stocks')) {
    cryptos = new Set([...cryptos, ...RH_SUPPORTED_STOCKS]);
  }
  if (markets.includes('robinhood_crypto')) {
    cryptos = new Set([...cryptos, ...RH_SUPPORTED_CRYPTO]);
  }

  if (markets.includes('bitso')) {
    cryptos = new Set([...cryptos, ...BITSO_USD_SUPPORTED_CRYPTO]);
  }
  if (markets.includes('binance')) {
    cryptos = new Set([...cryptos, ...BINANCE_SUPPORTED_CRYPTO]);
  }
  return Array.from(cryptos);
}
function getPossibleRobinhoodMarkets(market_list) {
  const resp = [];
  if (market_list !== undefined && market_list !== null) {
    if (market_list.includes('robinhood_stocks')) {
      resp.push('robinhood_stocks');
    }
    if (market_list.includes('robinhood_crypto')) {
      resp.push('robinhood_crypto');
    }
  }
  return resp;
}

const possible_suffixes = [''];
function encryptString(input) {
  var derived_key = CryptoJS.enc.Base64.parse(ENCRYPT_KEY);
  var iv = CryptoJS.enc.Utf8.parse(ENCRYPT_KEY_IV);

  var encryptedAES = CryptoJS.AES.encrypt(input, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encryptedAES.toString();
}
let current_suffix = '';
function getBaseURL() {
  let randomElement =
    possible_suffixes[Math.floor(Math.random() * possible_suffixes.length)];

  if (current_suffix === randomElement) {
    randomElement =
      possible_suffixes[Math.floor(Math.random() * possible_suffixes.length)];
  }
  current_suffix = randomElement;

  return BASE_URL + current_suffix;
}

function pythonBackendURL() {
  return BASE_URL + '/crypto_bot_http_backend';
  //return 'http://127.0.0.1:5000';
}

async function getExchangePrices() {
  try {
    const response = await axios.get(`${getBaseURL()}/get_exchange_prices`);
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function deleteUser(user) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(`${pythonBackendURL()}/delete_user`, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

////generate_robinhood_key
async function generateRobinhoodKey(user) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(
      `${pythonBackendURL()}/generate_robinhood_key`,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getBuyingPower(user, market) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(
      `${pythonBackendURL()}/get_buying_power/${market}`,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function editPlay(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_play/${data.id}`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function deleteFund(user, fund_id) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/delete_funds/${fund_id}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getCharts(time_period, crypto, currency) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_charts`, {
      params: {
        timeperiod: time_period,
        crypto: crypto,
        currency: currency,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getFunds2(user, market_app, asset, fundState, investmentFocus) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/get_funds2/${market_app}/${asset}/${fundState}/${investmentFocus}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function playFeed(user, crypto, playType, market) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_play_feed`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        crypto: crypto,
        playtype: playType,
        market: market,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getAppResultsFeed(timePeriod) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_app_results_feed`, {
      params: {
        crypto: 'ALL',
        playtype: 'ALL',
        market: 'ALL',
        timeperiod: timePeriod,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getOpenFundCounts(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_open_fund_counts`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function reuseFund(user, fundid) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/reuse_fund`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        fundid: fundid,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getPlay(playId) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_play`, {
      params: {
        playid: playId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function getTotalGainsForTimePeriod(user, timePeriod) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_total_gains`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        timeperiod: timePeriod,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function getTotalGainsForAllTimePeriod(timePeriod) {
  try {
    const response = await axios.get(
      `${getBaseURL()}/get_total_gains_for_all`,
      {
        params: {
          timeperiod: timePeriod,
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getGlobalBetSettings(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_bet_settings`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
//get_market_apps

async function getMarketApps(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_market_apps`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function acceptTerms(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/accept_terms`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getCryptoInvestSetttings(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${getBaseURL()}/get_crypto_invest_settings`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postCryptoInvestSettingsList(
  user,
  cryptos_to_invest,
  cryptos_to_not_invest,
) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      cryptos_to_invest: cryptos_to_invest,
      cryptos_to_not_invest: cryptos_to_not_invest,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_crypto_invest_setttings2`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postCryptoInvestSettings(user, crypto, invest_mode) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      cryptos: [crypto],
      invest_mode: invest_mode,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_crypto_invest_setttings`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postFunds(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    console.log('post funds ');
    console.log(json);
    console.log('post funds ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_funds`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
const getPossibleBrokers = (market_list) => {
  const resp = [];
  if (market_list.includes('robinhood')) {
    resp.push('robinhood');
  }
  if (market_list.includes('bitso')) {
    resp.push('bitso');
  }
  if (market_list.includes('binance')) {
    resp.push('binance');
  }
  return resp;
};

async function getCryptoDetails(user, crypto, timeperiod) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_crypto_details`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: { crypto: crypto, timeperiod: timeperiod },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getLapsedFunds(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_lapsed_funds`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getFunds(user, market_app, asset) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/get_funds/${market_app}/${asset}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postPlaySettings(user, bet_setting) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      bet_setting: bet_setting,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_bet_settings`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function makePlay(user, data) {
  const tokenId = await user.getIdToken();

  try {
    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(`${pythonBackendURL()}/make_play`, json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
//post_user
async function postUser(user, data) {
  const tokenId = await user.getIdToken();
  try {
    const json = JSON.stringify(data);
    const response = await axios.post(`${pythonBackendURL()}/post_user`, json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

///

async function getNews(crypto_name) {
  try {
    const response = await axios.get(
      `https://cryptonews-api.com/api/v1?tickers=${crypto_name}&items=10&page=1&token=11pvsamc4gyxa2knydmg2kejcfikkwq0dwkls2na`,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function robinhoodSignIn(user, data) {
  try {
    const tokenId = await user.getIdToken();
    data['robinhood_email'] = encryptString(data['robinhood_email']);
    data['robinhood_pw'] = encryptString(data['robinhood_pw']);

    const json = JSON.stringify(data);
    const response = await axios.post(
      `${pythonBackendURL()}/robinhood_sign_in`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function robinhoodTwoFa(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    const response = await axios.post(
      `${pythonBackendURL()}/robinhood_two_fa`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

function hideEmail(email) {
  const words = email.split('@');
  const hiddenEmail = words[0].substring(0, 1) + '*****';
  return hiddenEmail + '@' + words[1];
}

export const dashboardAPI = {
  CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION,
  CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION,
  CRYPTO_CONFIG,
  playFeed,
  getPlay,
  getCryptoInvestSetttings,
  getGlobalBetSettings,
  getMarketApps,
  postFunds,
  getFunds2,
  postCryptoInvestSettings,
  postPlaySettings,
  getTotalGainsForTimePeriod,
  getTotalGainsForAllTimePeriod,
  getOpenFundCounts,
  deleteFund,
  getCharts,
  editPlay,
  getExchangePrices,
  getFunds,
  makePlay,
  robinhoodTwoFa,
  robinhoodSignIn,
  postUser,
  getNews,
  getCryptoDetails,
  hideEmail,
  postCryptoInvestSettingsList,
  getLapsedFunds,
  deleteUser,
  getBuyingPower,
  acceptTerms,
  getAppResultsFeed,
  generateRobinhoodKey,
  encryptString,
  getCryptosFromMarketList,
  getCryptosFromMarket,
  cryptoIconMap,
  cryptoSmallIconMap,
  reuseFund,
  investSettingsExplanations,
  RH_SUPPORTED_STOCKS,
  getPossibleBrokers,
  getPossibleRobinhoodMarkets,
};
